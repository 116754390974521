<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <div v-if="this.errore_messaggio == ''">
            <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
            <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
          </div>
          <div v-else>
            <h4 class="mt-0 mb-0 text-danger">{{ this.errore_messaggio }}</h4>
          </div>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'area-clienti-dashboard' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna alla Dashboard
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-else>
        <h2 class="mb-2">Licenza n. {{ this.dati.licenseData.ref_code }}</h2>
        
        <form-wizard
          ref="myWizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          :hide-buttons="true"
          shape="square"
          :start-index="tabIniziale"
          :prevent-cross-tab="true"
        >
          
          <tab-content title="DATI AZIENDALI"></tab-content>

          <tab-content title="CONTRATTO"></tab-content>

          <tab-content
            title="PAGAMENTO"
          >
            <div v-if="stato_licenza == 'omaggio_auto_attivato'">
              <h3>Licenza attiva</h3>
              messaggio da definire + link alla visualizzazione della licenza (dovremo cambiare la rotta di questo wizzard)
            </div>
            <div v-else>
              <!--
              <h3>Paga su Stripe Hosted</h3>
              <p>verrai reindirizzato su Stripe.com per pagare (attivo sono per fare si test) ...</p>
              <b-button variant="outline-primary" class="text-uppercase mb-3"  @click="PagaContratto()">Paga su Stripe</b-button>
              
              <hr />
              -->
              
              <b-row class="justify-content-md-center">
                <b-col md="10" lg="6">
                  <paymentform 
                    field_stripe_api_key = "pk_test_519861GIHR2Kx1sUUGS1syJpea3Noe1NP8ypNCsKkMFOTFjjkwnZyWKvLKdg3KEY8OcQfK6dwtXkKgXFtrmRwXcIV00QZ5HNGox"
                    :field_payment_intent = 'this.dati.licenseData.payment_intent_secret_stripe'
                    :field_license_id = 'this.id_licenza' 
                    :field_customer = 'this.dati.licenseData.user.stripe_id'
                    :field_codice_contratto = 'this.dati.licenseData.ref_code' 
                    :field_product_id = 'this.dati.licenseData.product_id_stripe'
                    :field_is_subscription = 'this.isSubscription'
                  />

                </b-col>
              </b-row>

            </div>
          </tab-content>

          <tab-content title="RIEPILOGO"></tab-content>
      
        </form-wizard>



    </div>

      
  </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BSidebar, VBToggle, BBadge, BForm, BFormGroup, BFormCheckbox, BFormInput, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver, localize, configure  } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import {
  required, length,
} from '@validations'
import moment from 'moment'
import paymentform from '@/@core/components/paymentform/paymentform_v4.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,
    BForm, 
    BFormGroup, 
    BFormCheckbox, 
    BFormInput, 
    VBTooltip, 

    showdata,
    paymentform,

    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,

    ValidationProvider,
    ValidationObserver,
    it,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      Caricato: false,
      errore_caricamento: false,
      //id_licenza: router.currentRoute.params.id_riga,
      id_licenza: '',
      dati: [],
      stato_licenza: 'da_accettare',
      errore_messaggio: '',

      Caricato_testi_contratto: false,
      errore_testi_contratto: false,

      isSubscription: false,

      tabIniziale: 2,
      
      required,
      length,
      
      action_press_otp: false,
      action_press: false,
      action_press_donwload_plico: false,

      btn_verifica: true,
      btn_scaricare: false,
      link_download_pdf: '',
    }
  },
  created() {
    //alert("id passato -> "+router.currentRoute.params.id_riga);

    this.id_licenza = parseInt(router.currentRoute.params.id_riga, 10);

    this.$http.get('api/customer/license/card/'+this.id_licenza).then(response => { 
        /*  
        console.log(response)
        console.log("stato esterno -> "+response.status)
        console.log("stato interno -> "+response.data.statusCode)
        */

        if(response.status===200){
          //ok dati ricevuti
          // check autorizzazione ad accedere ai dati oppure no
          if(response.data.statusCode===200){
            //ok autorizzato ! => proseguo
            this.dati = response.data.reply

            console.log("FINE estrazioni dati licenza --------------------")
            console.log(this.dati)

            //FONDAMENTALE PER STRIPE !!
            if(this.dati.licenseData.type == 'Abbonamento'){
              this.isSubscription = true;
            } else {
              this.isSubscription = false; //OneShot
            }

            //FONDAMENTALE PER LA GESTIONE CORRETTA DI STRIPE ELEMENTS
            if(this.dati.licenseData.payment_intent_secret_stripe == null){
              //se è null lo trosformiamo in stringa vuota altrimenti fa casino
              this.dati.licenseData.payment_intent_secret_stripe = ''
            }            


            if(this.dati.licenseData.contract === null){
              //non è stato ancora accettato il contratto (manca proprio i dati nel DB) - nenache fatto un primo tentativo da parte del cliente
              // torna alla pagina di show della licenza
              this.$router.replace('/area-clienti/licenze/show/'+this.id_licenza)

            } else {
              if(this.dati.licenseData.contract.accepted_at === null){
                //licenza da accettare da parte del cliente
                
                //redirect verso pagina di show licenza
                this.$router.replace('/area-clienti/licenze/show/'+this.id_licenza)

              } else {
                if(this.dati.licenseData.status.id == '25'){
                  //licenza già accettata => visualizzo il pulsante "paga su stripe"
                  /*
                  ma qui dovrei sapere dati dati dell'api se è già stato creata la subscription su Stripe per questa licenza
                  così da visualizzare sono in caso negativo il pulsante per pagare !!!!!
                  */

                  /* forzo il passaggio al terzo step => PAGAMENTO */
                  console.log("start dal PAGAMENTO")

                  if(this.dati.licenseData.price == 0){
                    //licenza omaggio
                    this.stato_licenza = 'omaggio_auto_attivato'
                  } else {
                    this.stato_licenza = 'accettato'
                  }

                  this.Caricato = true;
                                    

                } else {
                  //qualsiasi altro stato della licenza ...

                  //redirect verso pagina di show licenza
                  this.$router.replace('/area-clienti/licenze/show/'+this.id_licenza)
                }
              }
            }
            
          } else {
            if(response.data.statusCode===403){
              //accesso ad una licenza che non è del cliente loggato, furbetto beccato!
              this.errore_messaggio = "Operazione non autorizzata! stai cercando di accedere ad una licenza non di tua pertinenza."
            }
            
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          console.log("qui ------------------")
          if(response.status===401){
            //accesso "Unauthenticated" -> accade quando si sviene sloggati dall'api
            console.error("Errore api -> "+response.data.reply['message'])

            if(logoutGeneral() === true){
              // Redirect to login page
              //this.$router.push({ name: 'auth-login' })
              this.$router.replace('/login?status=again')
            }
          }

          this.Caricato = false;
          this.errore_caricamento = true;
        }
      
      }).catch(e => {
        /*
        this.Caricato = false;
        this.errore_caricamento = true;
        */

        console.log("Errore api ......")
        console.log(e)

        this.Caricato = false;
        this.errore_caricamento = true;

      });
    

    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })
    // Imposta la lingua predefinita
    localize('it');
  },
  methods: { 
    
    PagaContratto(){
      this.Caricato = false;

      this.$http.post('/api/customer/stripe/checkout/'+this.id_licenza).then(response => { 
        console.log(response)
        console.log("redirect ->\n\n"+response.data.reply.checkout_url)

        // OLD VERSION
        // redirect verso stripe
        window.location.href = response.data.reply.checkout_url;
        
        //NON RIMUOVO MAI IL LOADER COSì NON VEDO LO SFARFALLIO DELLA PAGINA ;-)
        //this.Caricato = true;
      
      }).catch(e => {
        this.Caricato = true;
        console.log(e);
      }); 

    },

    formattazioneOrario(value, stringa){
      return moment(String(value)).locale('it').format(stringa)
    },
    
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';

.wizard-header{
  display: none;
}

/* disabilità il ckick sui titoli del wizzard */
.vue-form-wizard .wizard-nav-pills a, .vue-form-wizard .wizard-nav-pills li{
  cursor: not-allowed;
  pointer-events: none;
}

.box_testi_contratto{
  width: 100%;
  max-height: 90px;
  overflow-y: visible;
  overflow-x: hidden;
  background-color: rgba(34, 41, 47, 0.03);
  border: 1px solid rgba(34, 41, 47, 0.08);
  padding: 6px 10px;
  font-size: 11px;
}
.box_testi_contratto p{
  line-height: 13px;
  padding-bottom: 0px;
  margin-bottom: 9px;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #009688 !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .active {
  color: #009688 !important;
}
</style>
